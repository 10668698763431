@media (max-width: 750px) {
    .img1 {
        top: 44.8px;
        width: 100px;
        height: 105px;
        left: 124.4px;
      }
      
      .img2 {
        top: 62.8px;
        width: 109px;
        height: 42px;
      }
      
      .img3 {
        right: 10px;
        height: 7vw;
        width: 9vw;
      }
      
      .img4 {
        left: 1vw;
        top: 18vw;
        height: 6vw;
      }
      
      
      .img5 {
        width: 14vw;
        bottom: 1vw;
        left: 10vw;
      }
      
      .img6 {
        bottom: 7vw;
        width: 12vw;
      }
      
      .img7 {
          bottom: 1vw;
          width: 10vw;
          right: 50vw;
      }
      
      .img8 {
        right: 10vw;
        bottom: 2vw;
        width: 8vw;
      }

    .head {
        width: 100%;
        background-color: #FFCE32;
        height: 13.5vh !important;
        padding-left: 5vw;
        padding-right: 5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
    }

    .heroSection .title {
        color: #023020;
        font-size: 5vw !important;
        font-weight: 400;
        line-height: 5.1vw !important;
        text-align: center;
        width: 87%;
    }

    .heroSection .descr {
        color: #606060;
        font-size: 2.6vw !important;
        font-weight: 600;
        margin-top: 2.2vw !important;
    }

    .STH {
        color: #023020;
        font-size: 4.5vw !important;
        line-height: 4.5vw !important;
        margin-bottom: 1vw;
    }

    .STC {
        color: #606060;
        font-size: 2.3vw !important;
        font-weight: 400;
        line-height: 2.3vw !important;
    }

    .stickerText {
        text-align: right;
        transform: translateY(-20%);
        width: 60% !important;
        padding-top: 1vw !important;
    }

    .slcContent {
        background-color: #ffce32;
        display: flex;
        font-size: 1.8vw !important;
        font-weight: 800;
        height: 10vh;
        line-height: 2vw !important;
        padding: .5vw 1vw;
        text-align: center;
        width: 18vw !important;
    }

    .FCC1 {
        color: #023020;
        font-size: 4vw !important;
        font-weight: 400;
        line-height: 4.1vw !important;
        margin-bottom: 1vw;
        width: 96% !important;
    }

    .FCC2 {
        border-image-slice: 1;
        background-color: #d0a42c;
        box-shadow: 10px 10px 15px -5px rgba(255, 255, 255, 0.5);
        width: fit-content;
        font-size: 3vw !important;
        color: #FFFFFF;
        padding: 0.8vw 1.6vw;
        font-weight: 800;
        border-radius: 4px !important;
        position: relative;
    }

    .hooooooooooooo {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        position: absolute;
        top: 0;
        width: 100%;
        height: 20% !important;
        left: 0;
    }

    .footerComponent {
        background-color: #023020;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2vw !important;
        color: white;
        font-size: 2.6vw !important;
        padding-top: 1vw;
        padding-bottom: 1vw;
        position: relative;
    }

    .insta1 {
        width: 3vw !important;
        height: 3vw !important;
        object-fit: cover;
    }

    .blackbox {
        width: 9vw !important;
        height: 4vw !important;
    }

    .lineInFoot {
        height: 4vw !important;
        width: 0%;
        border: 1px solid white;
    }

    .praikrma {
        width: 9vw !important;
        height: 5vw !important;
    }

    .ocassionalCopyright {
        color: #F4F4F2;
        font-size: 1.8vw !important;
        position: absolute;
        right: 4vw;
        top: 45%;
    }

    .footerComponent {
        background-color: #023020;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2vw !important;
        color: white;
        font-size: 2.6vw !important;
        padding-top: 2vw;
        padding-bottom: 4vw;
        position: relative;
    }

    .ocassionalCopyright {
        color: #F4F4F2;
        font-size: 1.8vw !important;
        position: absolute;
        right: 4vw;
        top: 60%;
    }

    .sayHi {
        display: none;
    }

    .linenascPascal{
        display: none;
    }

    .theForum {
        display: flex;
        flex-direction: column;
        gap: 2vw;
        width: 100%;
    }
}


@media (max-width: 500px) {
    html {
        overflow-x:hidden;
    }
    .head img:nth-child(1) {
        width: 32vw !important;
        height: auto;
    }

    .head img:nth-child(2) {
        width: 8vw !important;
        height: auto;
    }

    .heroSection .title {
        color: #023020;
        font-size: 6vw !important;
        font-weight: 400;
        line-height: 6.1vw !important;
        text-align: center;
        width: 87%;
    }

    .heroSection .descr {
        color: #606060;
        font-size: 3vw !important;
        font-weight: 600;
        margin-top: 2.2vw !important;
        max-width: 80%;
        text-align: center;
    }

    .heroSection {
        height: 86.5vh !important;
    }

    .hs2 {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;
        gap: 2vw;
    }

    .content {
        color: #023020;
        font-size: 4.3vw !important;
        width: 40vw !important;
    }

    .line {
        border: 1px solid #023020;
        height: 15vh !important;
        width: 0px;
    }

    .stickerText {
        text-align: right;
        transform: translateY(-20%);
        width: 60% !important;
        padding-top: 5vw !important;
    }

    .STH {
        color: #023020;
        font-size: 5.2vw !important;
        line-height: 5.2vw !important;
        margin-bottom: 2vw;
    }

    .STC {
        color: #606060;
        font-size: 3vw !important;
        font-weight: 400;
        line-height: 3vw !important;
    }

    .sticker img {
        width: 25vw;
        height: auto;
        transform: translateY(40%);
    }

    .gc50 .sticker img {
        transform: translateY(-56%);
    }

    .gc50 {
        background-color: #023020;
        background-image: none !important;
        padding-bottom: 12vw !important;
        flex-direction: column !important;
    }

    .gc20 .sticker img {
        transform: translateY(-72%);
    }

    .gc20 {
        padding-bottom: 4vw !important;
    }

    .gynoxuuu .sticker img {
        transform: translateY(-45%);
    }

    .slc {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        background-image: url(http://localhost:3002/static/media/cellBGCI.cd55f56c421a867ab8fd.png);
        gap: 2vw;
        align-items: center;
        justify-content: center;
        padding: auto;
        align-content: center;
        justify-items: center;
        padding-top: 4vw;
        padding-bottom: 4vw;
        padding-left: 4vw;
        padding-right: 4vw;
        border-bottom: 5px solid #FFCE32;
    }

    .slcContent {
        background-color: #ffce32;
        display: flex;
        font-size: 3.8vw !important;
        font-weight: 800;
        height: 11vh;
        line-height: 4vw !important;
        padding: .5vw 1vw;
        text-align: center;
        width: 42vw !important;
    }

    .footerComponent {
        background-color: #023020;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2vw !important;
        color: white;
        font-size: 2.6vw !important;
        padding-top: 8vw !important;
        padding-bottom: 8vw !important;
        position: relative;
    }

    .insta1 {
        width: 4vw !important;
        height: 4vw !important;
        object-fit: cover;
    }

    .blackbox {
        width: 10vw !important;
        height: 5vw !important;
    }

    .praikrma {
        width: 10vw !important;
        height: 6vw !important;
    }

    .insta2 {
        width: 4vw !important;
        height: 4vw !important;
        object-fit: cover;
    }

    .ocassionalCopyright {
        color: #F4F4F2;
        font-size: 1.8vw !important;
        position: absolute;
        right: 4vw;
        top: 66%;
    }

    /* .heroSection {
        background-image: url('./Images/mediaDefined.png') !important;
    } */

    .heroSection .title {
        color: #023020;
        font-size: 11vw !important;
        font-weight: 400;
        line-height: 11.1vw !important;
        text-align: center;
        width: 58%;
    }

    .heroSection .descr {
        color: #606060;
        font-size: 3.5vw !important;
        font-weight: 600;
        margin-top: 2.2vw !important;
        max-width: 55%;
        text-align: center;
    }

    .hs2 {
        grid-template-columns: repeat(1,1fr) !important;
        height: 100vh;
        justify-content: space-around;
        align-content: space-around;
    }

    .line {
        border: 1px solid #023020;
        height: 0px !important;
        width: 60% !important;
    }

    .content {
        color: #023020;
        font-size: 5.3vw !important;
        width: 48vw !important;
        text-align: center;
    }

    .stickerContent{
        flex-direction: column-reverse;
        width: 100%;
        padding-top: 2vw !important;
        padding-bottom: 1vw !important;
        justify-content: space-between;
        align-items: center;
    }

    .sticker img{
        transform: translateY(0) !important;
    }

    .stickerText {
        transform: translateY(0) !important;
        text-align: center !important;
        width: 75% !important;
    }

    .STH {
        color: #023020;
        font-size: 9vw !important;
        line-height: 9.2vw !important;
        margin-bottom: 3vw;
    }

    .STC {
        color: #606060;
        font-size: 4vw !important;
        font-weight: 400;
        line-height: 4vw !important;
    }

    .sticker img {
        width: 45vw !important;
        height: auto;
        transform: translateY(40%);
    }

    .slc {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .slcContent {
        background-color: #ffce32;
        display: flex;
        font-size: 4vw !important;
        font-weight: 800;
        height: 11vh;
        line-height: 4.2vw !important;
        padding: .5vw 1vw;
        text-align: center;
        width: 80vw !important;
    }

    .finalComponent {
        background-color: #F4F4F2;
        display: flex;
        padding: 4vw 5vw;
        gap: 5vw;
        align-items: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .finalComponent img {
        border: 3px solid black;
        border-radius: 25px;
        width: 85vw;
    }

    .FCC1 {
        color: #023020;
        font-size: 13vw !important;
        font-weight: 400;
        line-height: 12.1vw !important;
        margin-bottom: 5vw;
        width: 96% !important;
        text-align: center;
    }

    .FCcontent{
        align-items: center;
    }

    .FCC2 {
        border-image-slice: 1;
        background-color: #d0a42c;
        box-shadow: 10px 10px 15px -5px rgba(255, 255, 255, 0.5);
        width: fit-content;
        font-size: 6vw !important;
        color: #FFFFFF;
        padding: 1.8vw 2.6vw;
        font-weight: 800;
        border-radius: 4px !important;
        position: relative;
    }

    .foot1Text {
        position: absolute;
        top: 20%;
        left: 42%;
        background-color: #023020 !important;
        z-index: 22 !important;
        padding-left: 0.5vw;
        padding-right: 0.5vw;
    }

    .ocassionalCopyright {
        color: #F4F4F2;
        font-size: 1.8vw !important;
        position: absolute;
        right: 4vw;
        top: 17vw;
    }

    .borderFMO {
        width: 45%;
        border: 1px solid white;
        height: 4.5vh;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 30%;
        left: 27.5%;
        z-index: 1;
    }

    .borderFMO {
        width: 35%;
        border: 1px solid white;
        height: 4.5vh;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 30%;
        left: 33%;
        z-index: 1;
    }









    .headerPopup {
        display: flex;
        flex-direction: column;
        position: fixed;
        z-index: 22;
        height: 100vh;
        background-color: #FFCE32;
        right: -130%;
        top: 0;
        width: 100%;
        align-items: center;
        gap: 2vw;
        font-size: 10vw;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.822);
        padding-top: 2vw;
        padding-right: 3vw;
        transition: all 1s ease-in-out;
    }

    .contentFormOfCF {
        background-color: white;
        display: flex;
        z-index: 3;
        padding: 5vw 6vw;
        gap: 4vw;
        align-items: center;
        width: 85vw;
        border-radius: 10px;
        height: 50vh;
    }

    .theForum {
        display: flex;
        flex-direction: column;
        gap: 1.5vw;
        width: 100%;
        height: 100%;
        justify-content: space-around;
    }

    .theForum textarea {
        border: none;
        outline: none;
        border: 1px solid #B7B7B7;
        font-size: 3vw;
        resize: none;
        border-radius: 10px;
        height: 15vh;
        padding: 1vw;
    }

    .theForum input {
        font-size: 3vw;
    }

    .theForum span {
        font-size: 3vw;
    }

    .borderFMO {
        width: 35%;
        border: 1px solid white;
        height: 4.5vh;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 36%;
        left: 33%;
        z-index: 1;
    }

    .foot1Text {
        position: absolute;
        top: 25%;
        left: 42%;
        background-color: #023020 !important;
        z-index: 22 !important;
        padding-left: 0.5vw;
        padding-right: 0.5vw;
    }
    
    .img1 {
        top: 21.8px;
        width: 98px;
        height: 103px;
        left: 1.4px;
    }

    .img4 {
        left: -1vw;
        top: 40vw;
        height: 15vw;
    }

    .img3 {
        right: -2px;
        height: 17vw;
        width: 19vw;
    }

    .img2 {
        top: 29.8px;
        width: 109px;
        height: 42px;
    }

    .img6 {
        bottom: 9vw;
        width: 23vw;
        left: 1px;
    }

    .img8 {
        right: 5vw;
        bottom: 2vw;
        width: 15vw;
    }

    .img7 {
        bottom: 1vw;
        width: 20vw;
        right: 50vw;
    }

    .img5 {
        display: hidden !important;
    }

    .borderFMO {
        width: 50%;
        border: 1px solid white;
        height: 5.5vh;
        display: block;
        background-color: transparent;
        position: absolute;
        top: 31%;
        left: 25%;
        z-index: 1;
    }
    
    .ocassionalCopyright {
        color: #F4F4F2;
        font-size: 1.8vw !important;
        position: absolute;
        right: 4vw;
        top: 22vw;
    }
    
    .ocassionalContactUs {
        position: absolute;
        right: 3vw;
        top: 73%;
        background-color: #FFCE32;
        color: #023020;
        font-weight: 900;
        font-size: 1.25vw;
        padding: 0.5vw 1.75vw;
        border-radius: 5px;
    }
    
    
    
    .praikrma {
        width: 16vw !important;
        height: 13vw !important;
    }
    
    
    
    .blackbox {
        width: 16vw !important;
        height: 10vw !important;
    }
}
